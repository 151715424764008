<template>
  <BaseDetailSimple
    :input-attributes="inputAttributes"
    :leave-guard="leaveGuard"
    :query-values="queryValues"
    entity-type="herGreatBritain"
    locale-section="pages.herGreatBritain"
    field-section="herGB"
  />
</template>

<script>
import BaseDetailPage from "@/components/BaseDetailPage";

export default {
  name: "HerGreatBritainDetailPage",
  extends: BaseDetailPage,
  data() {
    return {
      queryValues: /* GraphQL */ `
        uri
      `,
      inputAttributes: {
        uri: { required: true }
      }
    };
  }
};
</script>
